import React, { useContext, useState } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";

import { Cover } from "./../components/books/Cover";
import LookInsideBtn from "../components/books/LookInsideBtn";
import Prices from "../components/books/Prices";

import { addToCart } from "../contexts/actions";
import { cartContext } from "../contexts";

import { GridContainer, Container } from "../grid";
import { Zoom } from "@mui/material";
import { navigate } from "gatsby";

const BookMeta = styled(Box)(({ theme }) => ({
  "& p": {
    margin: "0.25em 0",
    fontSize: "0.875rem",
    // lineHeight: '125%',
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem",
    },
  },
  "& p:nth-last-of-type(1 )": {
    margin: 0,
  },
}));

const PageBookSingle = ({ data, pageContext }) => {
  // console.log(data)

  const book = data.book;
  const theme = useTheme();
  const md2 = useMediaQuery(theme.breakpoints.up("md2"));

  const { cart, dispatchCart } = useContext(cartContext);

  const [inCart, setInCart] = useState(
    cart.findIndex(item => item.id === book.strapi_id) !== -1
  );

  const addCartHandler = ({ id, title, author, prices, cover, slug }) => {
    dispatchCart(
      addToCart(
        {
          id,
          title,
          author,
          prices,
          cover,
          slug,
          type: "book",
        },
        1
      )
    );
    setInCart(true);
  };

  // Set Description for SEO
  // get first paragraph
  let seoDesc = book.desc?.html.slice(
    book.desc.html.indexOf("<p>"),
    book.desc.html.indexOf("</p>")
  );

  if (seoDesc) {
    // strip tags
    seoDesc = seoDesc.replace(/(<([^>]+)>)/gi, "");
    // get first sentence
    seoDesc = seoDesc.slice(0, seoDesc.indexOf(".") + 1);
    // console.log(seoDesc)
  }

  const img = book.cover?.localFile.childImageSharp.gatsbyImageData;

  return (
    <>
      <Seo
        title={book.title}
        description={seoDesc}
        images={[
          {
            height: img.height,
            width: img.width,
            url: img.images.fallback.src,
          },
        ]}
      />

      {/* Hero Area */}
      <Box
        component="section"
        sx={theme => ({
          backgroundColor: theme.palette.grey1,
        })}
      >
        <Container pt={{ xs: 4 }}>
          <GridContainer alignItems="center">
            <Grid
              item
              xs={12}
              md2={book.category.slug === "kamisibaji" ? 6 : 4}
              xl={book.category.slug === "kamisibaji" ? 7 : 4}
            >
              <Cover
                sx={theme => ({
                  width: {
                    xs: book.category.slug === "kamisibaji" ? "100%" : "60%",
                    md2: "100%",
                  },
                  maxWidth: {
                    xs: book.category.slug === "kamisibaji" ? "auto" : 300,
                    xxxl: book.category.slug === "kamisibaji" ? "auto" : 400,
                  },
                  mb: { xs: 6, sm: 7, md2: 0 },
                  ml: { md2: book.category.slug === "kamisibaji" ? 0 : "auto" },
                  position: "relative",
                  overflow: "visible",
                })}
                img={book.cover?.localFile}
                alt={book.title}
                nagrada={book.nagrada}
                gatsbyimageprops={{
                  loading: "eager",
                }}
              />
            </Grid>
            <Grid
              xs={12}
              md2={book.category.slug === "kamisibaji" ? 6 : 8}
              xl={book.category.slug === "kamisibaji" ? 5 : 8}
              item
              sx={theme => ({
                display: "grid",
                gap: { xs: 5, sm: 6 },
              })}
            >
              <Box>
                <Typography variant="bookAuthor">{book.author}</Typography>
                <Typography variant="bookTitle">{book.title}</Typography>
                {!!book.subtitle && (
                  <Typography variant="bookSubtitle">
                    {book.subtitle}
                  </Typography>
                )}
                {!!book.collection && (
                  <Typography variant="bookCollection">
                    {book.collection}
                  </Typography>
                )}
              </Box>
              <Box>
                <BookMeta>
                  {book.originalTitle && (
                    <p className="small">
                      Naslov izvirnika: <strong>{book.originalTitle}</strong>
                    </p>
                  )}
                  <div dangerouslySetInnerHTML={{ __html: book.meta.html }} />
                  {book.format && <p className="small">{book.format}</p>}
                  {book.ISBN && <p className="small">ISBN: {book.ISBN}</p>}
                </BookMeta>
              </Box>
              <Box
                sx={theme => ({
                  display: { lg: "flex" },
                  gap: { lg: 4 },
                  alignItems: "center",
                })}
              >
                {book.attachments.map((a, k) => (
                  <LookInsideBtn key={k} file={a.file?.url}>
                    {a.label}
                  </LookInsideBtn>
                ))}
              </Box>
              <Box
                sx={theme => ({
                  display: { sm2: "flex" },
                  alignItems: "center",
                  mt: { xs: 3, md2: 0 },
                })}
              >
                <Prices
                  data={book.prices}
                  sx={theme => ({
                    fontSize: "1.25rem",
                    display: "flex",
                    gap: theme.spacing(6),
                  })}
                />
                {true && (
                  <Box
                    sx={theme => ({
                      mt: { xs: 7, sm2: 0 },
                      ml: { sm: "auto" },
                      display: "inline-block",
                    })}
                  >
                    <Box
                      sx={theme => ({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: {
                          xs: "center",
                          sm2: "center",
                        },
                      })}
                    >
                      <Button
                        sx={theme => ({
                          // width: {xs: '100%', sm: 'auto'},
                          backgroundColor: inCart
                            ? theme.palette.primary.main
                            : "transparent",
                          color: inCart
                            ? theme.palette.white
                            : theme.palette.primary.main,
                        })}
                        variant="outlined"
                        disabled={!book.available}
                        onClick={() => {
                          if (inCart) {
                            navigate("/kosarica");
                          } else {
                            addCartHandler({
                              id: book.strapi_id,
                              title: book.title,
                              author: book.author,
                              prices: book.prices,
                              cover: book.cover?.localFile,
                              slug: `/${book.category.slug}/${book.slug}`,
                            });
                          }
                        }}
                      >
                        {inCart ? "Zaključi nakup" : "Dodaj v košarico"}
                      </Button>

                      <Zoom in={inCart}>
                        <Box
                          component={"p"}
                          sx={{
                            fontSize: "0.75em",
                            lineHeight: "2em",
                            margin: 0,
                            marginBottom: "-2em",
                          }}
                        >
                          Knjiga je v košarici
                        </Box>
                      </Zoom>

                      {!book.available && (
                        <Box
                          component={"span"}
                          sx={{
                            fontSize: "0.75em",
                            lineHeight: "2em",
                            marginBottom: "-2em",
                          }}
                        >
                          {!book.prices.preorder && "Knjiga je razprodana"}
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
              {book.info?.data.info && (
                <Box>
                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: book.info.data.info }}
                  />
                </Box>
              )}
            </Grid>
          </GridContainer>
        </Container>
      </Box>

      {/* Description */}
      {book.desc && (
        <Box
          sx={theme => ({
            backgroundColor: theme.palette.white,
          })}
        >
          <Container pt={{ xs: 0, md: 0 }}>
            <GridContainer component="section">
              {md2 && <Grid item md2={4}></Grid>}
              <Grid item md2={7} className="content">
                <section
                  dangerouslySetInnerHTML={{ __html: book.desc.html }}
                ></section>
              </Grid>
            </GridContainer>
          </Container>
        </Box>
      )}
    </>
  );
};

export default PageBookSingle;

export const query = graphql`
  query ($id: [Int]) {
    book: strapiBook(strapi_id: { in: $id }) {
      strapi_id
      category {
        name
        slug
      }
      nagrada {
        text
        type
      }
      title
      originalTitle
      id
      slug
      subtitle
      author
      collection
      ISBN
      format
      available
      meta: childStrapiBookMetaTextnode {
        html: meta
      }
      desc: childStrapiBookDescTextnode {
        html: desc
      }
      prices {
        regular
        online
        discount
        uniform
      }
      attachments {
        label
        file {
          url
        }
      }
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1024)
          }
        }
      }
    }
  }
`;
